/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 newtyping.gltf 
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF, useAnimations, PerspectiveCamera  } from '@react-three/drei'

export function Newtyping(props) {
  const group = useRef()
  const camera = useRef()
   const { nodes, materials, animations } = useGLTF('/newtyping.gltf')
  const { actions,names } = useAnimations(animations, group)
  useEffect(()=>{
    actions[names[0]].reset().fadeIn().play()
  },[actions,names])
  
  return (
    <>
    <PerspectiveCamera
    ref={camera} 
    makeDefault   
    position={[160, 160, 160 ]}   
    fov={110}
    far ={-2000}
     near={150}
   
    zoom={3}
  />
    <group ref={group} {...props} dispose={null}>
      <group name="Scene"  position={[0, 0, 0]}>
        <group name="Sketchfab_model" position={[-63.76, 98.227, -4.351]} rotation={[-1.618, -0.006, -0.516]} scale={26.433}>
          <group name="root">
            <group name="GLTF_SceneRootNode" rotation={[Math.PI / 2, 0, 0]}>
              <group name="Inter@Cern_Typing_174" position={[-1.327, -3.589, -0.932]} rotation={[1.41, -0.128, -1.261]} scale={2.746}>
                <group name="GLTF_created_2" position={[-0.15, 0.105, -0.114]} rotation={[0.047, 0.177, -0.117]} scale={0.809}>
                  <primitive object={nodes.GLTF_created_2_rootJoint} />
                  <skinnedMesh name="Object_136" geometry={nodes.Object_136.geometry} material={materials.LaMaterial} skeleton={nodes.Object_136.skeleton} />
                </group>
              </group>
            </group>
          </group>
        </group>
        <group name="Sketchfab_model001" position={[-96.067, -3.733, -49.187]} rotation={[-1.513, 0.086, 0.64]} scale={0.813}>
          <group name="Collada_visual_scene_group" rotation={[Math.PI / 2, 0, 0]}>
            <group name="chair_woo">
              <mesh name="defaultMaterial" geometry={nodes.defaultMaterial.geometry} material={materials.up_1001} position={[-2.882, 6.016, 27.38]} />
              <mesh name="defaultMaterial001" geometry={nodes.defaultMaterial001.geometry} material={materials.up_1002} position={[-2.882, 6.016, 27.38]} />
            </group>
          </group>
        </group>
        <mesh name="Plane" geometry={nodes.Plane.geometry} material={materials['Material.001']} position={[-40.329, 48.17, 10.785]} rotation={[-0.057, -0.845, -0.014]} scale={[31.058, 1933.638, 78.864]} />
        <mesh name="Cube" geometry={nodes.Cube.geometry} material={materials['Material.001']} position={[-82.459, 23.172, 49]} rotation={[-0.057, -0.845, -0.014]} scale={18.869} />
        <mesh name="Cube001" geometry={nodes.Cube001.geometry} material={materials['Material.001']} position={[4.786, 18.154, -28.142]} rotation={[-0.057, -0.845, -0.014]} scale={18.869} />
        <mesh name="Cylinder" geometry={nodes.Cylinder.geometry} material={materials['Material.004']} position={[-77.121, 44.352, 55.625]} rotation={[-0.057, -0.845, -0.014]} scale={[-2.281, -4.487, -2.281]} />
        <mesh name="Cylinder001" geometry={nodes.Cylinder001.geometry} material={materials['Material.004']} position={[-89.396, 43.824, 41.799]} rotation={[-0.057, -0.845, -0.014]} scale={[-2.281, -4.487, -2.281]} />
        <mesh name="Cylinder002" geometry={nodes.Cylinder002.geometry} material={materials['Material.004']} position={[10.098, 40.391, -21.681]} rotation={[-0.057, -0.845, -0.014]} scale={[-2.281, -4.487, -2.281]} />
        <mesh name="Cylinder003" geometry={nodes.Cylinder003.geometry} material={materials['Material.004']} position={[-2.177, 40.244, -35.507]} rotation={[-0.057, -0.845, -0.014]} scale={[-2.281, -4.487, -2.281]} />
        <mesh name="Plane001" geometry={nodes.Plane001.geometry} material={materials['Material.010']} position={[-28.586, 5.152, 24.417]} rotation={[-0.057, -0.845, -0.014]} scale={[0.42, 2.296, 42.33]} />
        <mesh name="Plane002" geometry={nodes.Plane002.geometry} material={materials['Material.001']} position={[-28.464, 18.16, 23.813]} rotation={[-0.057, -0.845, -0.014]} scale={[0.42, 9.058, 41.75]} />
        <mesh name="Plane003" geometry={nodes.Plane003.geometry} material={materials['Material.008']} position={[-48.918, 10.416, 44.115]} rotation={[-0.057, -0.845, -0.014]} scale={[-0.679, -12.947, -1.985]} />
        <mesh name="Plane004" geometry={nodes.Plane004.geometry} material={materials.Material} position={[-29.124, 9.086, 26.391]} rotation={[-0.057, -0.845, -0.014]} scale={[-0.679, -12.947, -1.985]} />
        <mesh name="Plane005" geometry={nodes.Plane005.geometry} material={materials['Material.007']} position={[-6.548, 8.106, 6.729]} rotation={[-0.057, -0.845, -0.014]} scale={[-0.679, -12.947, -1.985]} />
        <mesh name="Cube002" geometry={nodes.Cube002.geometry} material={materials['default']} position={[4.786, 18.154, -28.142]} rotation={[-0.057, -0.845, -0.014]} scale={18.869} />
        <mesh name="Cube003" geometry={nodes.Cube003.geometry} material={materials['Material.001']} position={[-117.224, 15.179, 10.212]} rotation={[-0.057, -0.845, -0.014]} scale={[34.673, 12.42, 18.869]} />
        <mesh name="Cube004" geometry={nodes.Cube004.geometry} material={materials['default']} position={[-117.224, 15.179, 10.212]} rotation={[-0.057, -0.845, -0.014]} scale={[34.673, 12.42, 18.869]} />
        <mesh name="Cube005" geometry={nodes.Cube005.geometry} material={materials['Material.004']} position={[-117.224, 15.179, 10.212]} rotation={[-0.057, -0.845, -0.014]} scale={[34.673, 12.42, 18.869]} />
        <mesh name="Cube006" geometry={nodes.Cube006.geometry} material={materials['default']} position={[-117.224, 15.179, 10.212]} rotation={[-0.057, -0.845, -0.014]} scale={[34.673, 12.42, 18.869]} />
        <mesh name="Cube007" geometry={nodes.Cube007.geometry} material={materials['default']} position={[-117.224, 15.179, 10.212]} rotation={[-0.057, -0.845, -0.014]} scale={[34.673, 12.42, 18.869]} />
        <mesh name="Cube008" geometry={nodes.Cube008.geometry} material={materials['Material.004']} position={[-117.224, 15.179, 10.212]} rotation={[-0.057, -0.845, -0.014]} scale={[34.673, 12.42, 18.869]} />
        <mesh name="Sphere" geometry={nodes.Sphere.geometry} material={materials['Material.001']} position={[-91.58, 6.512, 10.335]} rotation={[-0.057, -0.845, -0.014]} scale={-1.447} />
        <mesh name="Sphere001" geometry={nodes.Sphere001.geometry} material={materials['Material.001']} position={[-91.516, 13.329, 10.018]} rotation={[-0.057, -0.845, -0.014]} scale={-1.447} />
        <mesh name="Sphere002" geometry={nodes.Sphere002.geometry} material={materials['Material.001']} position={[-113.62, 12.379, -14.879]} rotation={[-0.057, -0.845, -0.014]} scale={-1.447} />
        <mesh name="Sphere003" geometry={nodes.Sphere003.geometry} material={materials['Material.001']} position={[-113.682, 5.751, -14.571]} rotation={[-0.057, -0.845, -0.014]} scale={-1.447} />
        <mesh name="Cube009" geometry={nodes.Cube009.geometry} material={materials['Material.004']} position={[-88, 63.146, 50.825]} rotation={[-0.057, -0.845, -0.014]} scale={[12.739, 12.573, 8.841]} />
        <mesh name="Cube011" geometry={nodes.Cube011.geometry} material={materials['Material.006']} position={[-88, 63.146, 50.825]} rotation={[-0.057, -0.845, -0.014]} scale={[12.739, 12.573, 8.841]} />
        <mesh name="Cube012" geometry={nodes.Cube012.geometry} material={materials['Material.002']} position={[-88, 63.146, 50.825]} rotation={[-0.057, -0.845, -0.014]} scale={[12.739, 12.573, 8.841]} />
        <mesh name="Cube013" geometry={nodes.Cube013.geometry} material={materials['Material.006']} position={[-88, 63.146, 50.825]} rotation={[-0.057, -0.845, -0.014]} scale={[12.739, 12.573, 8.841]} />
        <mesh name="Cube014" geometry={nodes.Cube014.geometry} material={materials['Material.002']} position={[-88, 63.146, 50.825]} rotation={[-0.057, -0.845, -0.014]} scale={[12.739, 12.573, 8.841]} />
        <mesh name="Plane008" geometry={nodes.Plane008.geometry} material={materials['Material.003']} position={[-44.057, 57.045, 32.345]} rotation={[3.085, 0.845, 0.014]} scale={[-1.14, -31.119, -23.658]} />
        <mesh name="Plane009" geometry={nodes.Plane009.geometry} material={materials['Material.003']} position={[-44.057, 57.045, 32.345]} rotation={[3.085, 0.845, 0.014]} scale={[-1.14, -31.119, -23.658]} />
        <mesh name="Plane010" geometry={nodes.Plane010.geometry} material={materials['Material.013']} position={[-44.057, 57.045, 32.345]} rotation={[3.085, 0.845, 0.014]} scale={[-1.14, -31.119, -23.658]} />
        <mesh name="Sphere004" geometry={nodes.Sphere004.geometry} material={materials['Material.003']} position={[-40.859, 51.085, 34.093]} rotation={[-0.057, -0.845, -0.014]} scale={[-9.982, -2.43, -13.667]} />
        <mesh name="Sphere005" geometry={nodes.Sphere005.geometry} material={materials['Material.003']} position={[-85.172, 50.676, 24.817]} rotation={[3.085, 0.845, -3.127]} scale={[6.331, 1.833, 3.899]} />
        <mesh name="Plane011" geometry={nodes.Plane011.geometry} material={materials['Material.003']} position={[-52.491, 50.131, 0.188]} rotation={[-0.057, -0.845, -0.014]} scale={[8.09, 8.267, 18.628]} />
        <mesh name="Plane012" geometry={nodes.Plane012.geometry} material={materials['Material.006']} position={[-52.491, 50.131, 0.188]} rotation={[-0.057, -0.845, -0.014]} scale={[8.09, 8.267, 18.628]} />
        <mesh name="Plane014" geometry={nodes.Plane014.geometry} material={materials['Material.004']} position={[-117.648, 28.017, 12.233]} rotation={[-0.057, -0.845, -0.014]} scale={[11.988, 9.204, 9.163]} />
        <mesh name="Plane015" geometry={nodes.Plane015.geometry} material={materials['default']} position={[-117.648, 28.017, 12.233]} rotation={[-0.057, -0.845, -0.014]} scale={[11.988, 9.204, 9.163]} />
        <mesh name="Plane016" geometry={nodes.Plane016.geometry} material={materials['Material.009']} position={[-117.648, 28.017, 12.233]} rotation={[-0.057, -0.845, -0.014]} scale={[11.988, 9.204, 9.163]} />
        <mesh name="Plane017" geometry={nodes.Plane017.geometry} material={materials['Material.009']} position={[-114.167, 39.781, 8.626]} rotation={[-0.057, -0.845, -0.014]} scale={[5.375, 8.53, 8.53]} />
        <mesh name="Plane018" geometry={nodes.Plane018.geometry} material={materials['Material.009']} position={[-109.369, 31.319, 6.388]} rotation={[-0.057, -0.845, -0.014]} scale={[5.375, 8.53, 8.53]} />
        <mesh name="Plane019" geometry={nodes.Plane019.geometry} material={materials['Material.009']} position={[-129.726, 28.354, -8.285]} rotation={[-0.057, -0.845, -0.014]} scale={[5.375, 8.53, 8.53]} />
        <mesh name="Plane006" geometry={nodes.Plane006.geometry} material={materials['Material.003']} position={[-14.548, 46.69, -34.762]} rotation={[-0.057, -0.845, -0.014]} scale={[8.09, 8.267, 18.628]} />
        <mesh name="Plane007" geometry={nodes.Plane007.geometry} material={materials['Material.006']} position={[-14.548, 46.69, -34.762]} rotation={[-0.057, -0.845, -0.014]} scale={[8.09, 8.267, 18.628]} />
        <mesh name="Sphere006" geometry={nodes.Sphere006.geometry} material={materials['Material.003']} position={[-34.398, 49.21, -19.215]} rotation={[3.085, 0.845, -3.127]} scale={[6.331, 1.833, 3.899]} />
        <mesh name="Plane013" geometry={nodes.Plane013.geometry} material={materials['Material.003']} position={[3.39, 56.69, -10.887]} rotation={[3.085, 0.845, 0.014]} scale={[-1.14, -31.119, -23.658]} />
        <mesh name="Plane020" geometry={nodes.Plane020.geometry} material={materials['Material.003']} position={[3.39, 56.69, -10.887]} rotation={[3.085, 0.845, 0.014]} scale={[-1.14, -31.119, -23.658]} />
        <mesh name="Plane021" geometry={nodes.Plane021.geometry} material={materials['Material.012']} position={[3.39, 56.69, -10.887]} rotation={[3.085, 0.845, 0.014]} scale={[-1.14, -31.119, -23.658]} />
        <mesh name="Sphere007" geometry={nodes.Sphere007.geometry} material={materials['Material.003']} position={[6.28, 49.447, -9.205]} rotation={[-0.057, -0.845, -0.014]} scale={[-9.982, -2.43, -13.667]} />
        <mesh name="Asset_4" geometry={nodes.Asset_4.geometry} material={materials['Asset 4']} position={[2.465, 71.801, -13.271]} rotation={[1.546, -0.058, 2.43]} scale={9.384} />
        <mesh name="Asset_4001" geometry={nodes.Asset_4001.geometry} material={materials['Asset 4']} position={[-45.324, 72.239, 30.208]} rotation={[1.546, -0.058, 2.43]} scale={9.384} />
      </group>
    </group>
    </>
  )
}

useGLTF.preload('/newtyping.gltf')
