import React, { useState } from 'react'
import MySong from './MySong'
import Marquee from 'react-fast-marquee'

import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt,faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faGithub } from "@fortawesome/free-brands-svg-icons";
import './css-files/contact-us.css'

function ContactUs({ img, theme, toggleMode }) {

  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_number: '',
    message: '',
  });
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_vdhrx9a', 'template_hyl73hj', e.target, '5nLdgQctU2OtWeoxE')
      .then(
        (result) => {

          toast.success('Email sent successfully');
          setFormData({
            user_name: '',
            user_email: '',
            user_number: '',
            message: '',
          });
        },
        (error) => {
          console.log(error.text);
          toast.error('Failed to send email');
        }
      );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <div className='btn-outer'>
        <div className='btn-inner'>
          <div className='both-botton'>
            <img src={img} alt='sun&moon' onClick={toggleMode} />
            <MySong theme={theme} />


          </div>
        </div>
      </div>

      <div className='contact-outer'>
        <div className='contact-inner'>
          {/* --services section heading first starts--*/}
          <div className="heading-right">
            <Marquee play pauseOnHover speed={20} >
              <h1 style={{ color: theme === 'light' ? '#000' : 'rgb(30, 124, 192)' }}>contact us  contact us  </h1>
            </Marquee>
          </div>
          {/* --services section heading first ends--*/}


          

          <div className='contact-flex' id='contact-flex'>
            <div className='contact-left-section'>
              <div className='social-links'>

                <ul className='list-navigation'>
                  <div className='list-heading list-heading-first'>
                    <h1  >location</h1>

                  </div>


                  <div className='list-icon'>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    <li className="nav-list">
                      tara mata house , near central bank kasumpti shimla 171009, himachal pradesh
                    </li>
                  </div>
                  <div className='list-heading list-heading-second'>
                    <h1 >get in touch</h1>
                  </div>
                  <div className='list-icon-flex'>
                  <div className='list-icon'>
                    <FontAwesomeIcon icon={faPhone} />
                    <li className="nav-list">
                      <a href='tel:6230097248'>9999999999</a>
                    </li>
                  </div>

                  <div className='list-icon'>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <li className="nav-list">
                      <a href='mailto:raaj73906@gmail.com'>raaj73906@gmail.com</a>
                    </li>
                  </div>
                  </div>

                </ul>
              </div>


              <div className='social-links'>
                <div className='list-heading list-heading-third'>
                  <h1>social media</h1>
                </div>
                <ul className='list-navigation list-nav-second'>
                  <div className='list-icon'>
                    <FontAwesomeIcon icon={faFacebook} />
                    <li className="nav-list">
                      <a href='/' target='_blank'>facebook</a>
                    </li>
                  </div>
                  <div className='list-icon'>
                    <FontAwesomeIcon icon={faInstagram} />
                    <li className="nav-list">
                      <a href='/' target='_blank'>Instagram</a>
                    </li>
                  </div>
                  <div className='list-icon'>
                    <FontAwesomeIcon icon={faGithub} />

                    <li className="nav-list">
                      <a href='/' target='_blank'>github</a>
                    </li>
                  </div>

                </ul>
              </div>
            </div>

            







            <div className='contact-right-section'>

              <ToastContainer />


              <form className="conatct-us form" onSubmit={sendEmail} id='my-fom'>
                <div className="mb-3">
                  
                  <input
                    type="text"
                    className="form-control modal-input-type"
                    name="user_name"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={formData.user_name}
                    onChange={handleInputChange}
                    placeholder='Enter Your Name'
                  />
                </div>
                <div className="mb-3">
                   
                  <input
                    type="email"
                    className="form-control modal-input-type"
                    name="user_email"
                    id="exampleInputPassword1"
                    value={formData.user_email}
                    onChange={handleInputChange}
                    placeholder=' Enter Your Email'
                  />
                </div>
                <div className="mb-3">
                   
                  <input
                    type="number"
                    className="form-control modal-input-type"
                    name="user_number"
                    id="exampleInputPassword1"
                    value={formData.user_number}
                    onChange={handleInputChange}
                    placeholder=' Enter Your Phone Number'
                  />
                </div>
                <div className="mb-3">
                  
                  <textarea
                    className="form-control modal-input-type"
                    name="message"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    value={formData.message}
                    onChange={handleInputChange}
                    placeholder='Enter Your Message'
                  ></textarea>
                </div>
                <div className='submit-button'>
                <button
                  type="submit"
                  className="btn btn-primary contact-submit-button"
                  style={{ backgroundColor: theme === 'dark' ? '#1e7cc0' : '#f24b74' }}
                >
                  Submit <span className='button-icon'> <FontAwesomeIcon icon={faArrowRight}/></span>
                </button>
               
                </div>
              </form>



            </div>
          </div>
        </div>

      </div>

    </>
  )
}

export default ContactUs









