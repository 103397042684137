import React from 'react'
import { Link } from 'react-router-dom'

function ErrorPage() {
  return (
   
   <>

   <h1>no result found go to  
    
    
    <Link to='/'>home</Link></h1>

   </>
  )
}

export default ErrorPage