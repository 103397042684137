import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactForm({ theme }) {
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_number: '',
    message: '',
  });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_vdhrx9a', 'template_hyl73hj', e.target, '5nLdgQctU2OtWeoxE')
      .then(
        (result) => {
           console.log(result)
          toast.success('Email sent successfully');
          setFormData({
            user_name: '',
            user_email: '',
            user_number: '',
            message: '',
          });
        },
        (error) => {
          console.log(error.text);
          toast.error('Failed to send email');
        }
      );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <ToastContainer />

      <button
        type="button"
        className="btn btn-primary contact-us-button"
        id="my-button"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        style={{ backgroundColor: theme === 'dark' ? '#1e7cc0' : '#f24b74' }}
      >
        Contact us
      </button>

      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" id="modal-outer">
          <div className="modal-content contact-us-content">
            <div className="modal-header contact-us-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel" style={{ color: theme === 'dark' ? '#1e7cc0' : '#f24b74' }}>
                Send Us a Query
              </h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div className="modal-body contact-us-model-body">
              <form className="conatct-us form" onSubmit={sendEmail}>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label modal-lable-type">
                    Enter Your Name
                  </label>
                  <input
                    type="text"
                    className="form-control modal-input-type"
                    name="user_name"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={formData.user_name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label modal-lable-type">
                    Enter Your Email
                  </label>
                  <input
                    type="email"
                    className="form-control modal-input-type"
                    name="user_email"
                    id="exampleInputPassword1"
                    value={formData.user_email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label modal-lable-type">
                    Enter Your Phone Number
                  </label>
                  <input
                    type="number"
                    className="form-control modal-input-type"
                    name="user_number"
                    id="exampleInputPassword1"
                    value={formData.user_number}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleFormControlTextarea1" className="form-label modal-lable-type">
                    Enter Your Message
                  </label>
                  <textarea
                    className="form-control modal-input-type"
                    name="message"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    value={formData.message}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary contact-submit-button"
                  style={{ backgroundColor: theme === 'dark' ? '#1e7cc0' : '#f24b74' }}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
